<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="sortedPronostics"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          data-toggle="modal"
          data-target="#add-pronostic"
          class="btn btn-outline-primary btn-icon"
          v-if="canAdd"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
    <modal
      id="add-pronostic"
      :footer="false"
      title="Ajouter un pronostic"
      size="x-large"
    >
      <form
        class="form-group"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <animal-detail :show-detail="true" />
        <div class="form-group">
          <label for="add-type">Pronostic</label>
          <select
            name="add-type"
            id="add-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le pronostic'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in types"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-info">Information supplementaire</label>
          <textarea
            name="add-info"
            id="add-info"
            rows="5"
            class="form-control"
            v-model="info"
          />
        </div>
        <div class="form-group text-right">
          <button
            class="btn btn-primary"
            :class="loading? 'disabled': ''"
          >
            <span
              class="spinner-border m-1"
              role="status"
              v-if="loading"
            /> 
            <span v-else> Ajouter </span>
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
    <script>
    import {mapGetters, mapMutations} from 'vuex'
    import moment from 'moment'
    import DataTable from '../../../components/dataTable/local.vue'
    import navbar from '../../../components/navbar.vue'
    import AnimalDetail from '../../../components/identification/animal/detailInform.vue'
    import {  TEXT_TYPE, DATE_TYPE, COMPONENT_TYPE} from '../../../components/dataTable/dataType'
    import Modal from '../../../components/modal.vue'
import { ADD_PRONOSTIC } from '../../../graphql/sanitaire'
    const Animal = () => import('../../../components/sanitaire/animal.vue')
    const CreatedBy = () => import('../../../components/sanitaire/createdBy.vue')
    const Prononstic = () => import('../../../components/sanitaire/pronostic/type.vue')
    const Actions = () => import('../../../components/sanitaire/pronostic/actions.vue')
    export default {
        components: {navbar, DataTable, Modal, AnimalDetail },
        data(){
            return {
                navbarItems: [
                {
                    libelle: 'Sanitaire'
                },
                {
                    libelle: 'Pronostic',
                }
                ],
                pageIcon: 'la-id-badge',
                pageTitle: 'Pronostic',
                pageDescription: 'Pronostic vital des animaux',  
              type: null,
              info: null,  
              has_error: false,
              error_msg: null
            }
        },
        methods: {
            ...mapMutations({
                done: 'DONE',
                setLoading: 'SET_LOADING'
            }),
            initForm(){
                this.setLoading(false)
                this.type = null
                this.info = null
                this.has_error = false
                this.error_msg = null
            },
            adding(){
                this.setLoading(true)
                let data = {
                    animal: this.selectedAnimal,
                    type: this.type,
                    info: this.info
                }
                this.$apollo.mutate({
                    mutation: ADD_PRONOSTIC,
                    variables: {
                        "pronostic": {
                            ...data
                        }
                    },
                    update: (d) => {
                        console.log(d)
                        console.log(`Pronostic ${this.libelle} add successfully`)
                    }
                }).then(() => {
                    this.initForm()
                    this.done()
                }).catch((error) => {
                    this.setLoading(false)
                    console.error(error)
                    this.has_error = true
                    this.error_msg = error
                })
            }
            
        },
        computed: {
            ...mapGetters({
                selectedAnimal: 'identification/selectedAnimal',
                types: 'sanitaire/typePronostics',
                pronostics: 'sanitaire/pronostics',
                is_super_admin: 'auth/is_super_admin',
                is_veterinaire: 'auth/is_veterinaire',
                loading: 'loading'
            }),
            sortedPronostics(){
              return [...this.pronostics].sort((b,a) => moment(a.createdAt).format('X') - moment(b.createdAt).format('X'))
            },
            headers(){
                return [
                    {label: 'ID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                    {label: 'Animal', name: 'animal', type: COMPONENT_TYPE, component: Animal},
                    {label: 'Pronostic', name: 'type', type: COMPONENT_TYPE, component: Prononstic},
                    {label: 'Createur', name: 'createBy', type: COMPONENT_TYPE, component: CreatedBy},
                    {label: 'Date de création', name: 'createdAt', type: DATE_TYPE, sortable: true},
                    {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions, visible: this.canAdd}
                ]
            },
            canAdd(){
              return this.is_super_admin ||
              this.is_veterinaire
            }
        }
    }
    </script>